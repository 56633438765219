import DashAddSettings from '../components/dashcustomers/DashAddSettings';
import React, { useState , useEffect, useContext } from 'react';
import DashNav from '../components/dashboard/DashNav';
import DashSlide from '../components/dashboard/DashSlide';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export default function DashCustomers(){
    const [openMenu , setOpenMenu] = useState(false);
    const {dispatch} = useContext(AuthContext)
    const navigate = useNavigate();
    const handleOpenMenu = () => {
        setOpenMenu(!openMenu)
    }

    const handleLogout = () => {
        dispatch({ type: "LOGOUT" });
        navigate("/login");
    };

    return(
        <div className="overflow-x-hidden">   
            <DashNav handleLogout={handleLogout} handleOpenMenu={handleOpenMenu} />
            <div className="container-fluid py-5 my-5">
                <div className="row g-2">
                    <div>
                        <DashSlide 
                            openMenu={openMenu} 
                            handleOpenMenu={handleOpenMenu} 
                        />
                    </div>
                    <div className="col text-black dashboard-card mt-4 justify-content-center align-items-center">
                        <DashAddSettings/>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useState, useContext } from 'react';
import DashNav from '../components/dashboard/DashNav';
import DashSlide from '../components/dashboard/DashSlide';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import TutorialVideo from '../components/tutorial/TutorialVideos';

export default function DashTutorial(){
    const [openMenu , setOpenMenu] = useState(false);
    const {dispatch} = useContext(AuthContext)
    const navigate = useNavigate();
    const handleOpenMenu = () => {
        setOpenMenu(!openMenu)
    }

    const handleLogout = () => {
        dispatch({ type: "LOGOUT" });
        navigate("/login");
    };

    return(
        <div className="overflow-x-hidden">   
            <DashNav handleLogout={handleLogout} handleOpenMenu={handleOpenMenu} />
            <div className="contaier-fluid pt-5 my-5">
                <div className="row g-2">
                    <div>
                        <DashSlide 
                            openMenu={openMenu} 
                            handleOpenMenu={handleOpenMenu} 
                        />
                    </div>
                    <div className="col text-black dashboard-card mt-4">
                        <TutorialVideo />
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import CarDetail from '../components/cardetail/CarDetail';

export default function CarReview(){
    const {collectionId,  productId } = useParams();
    const [product, setProduct] = useState(null);
    


    return (
        <>
            <Navbar />
            <CarDetail collectionId={collectionId} productId={productId} product={product} setProduct={setProduct} />
            <Footer />
        </>
    );
};




import DashHomeCard from '../dashhome/DashHomeCard'
import React, { useEffect, useState } from 'react';
import { auth } from '../../firebase';

export default function DashHome(){
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (auth.currentUser) {
            setEmail(auth.currentUser.email);
        }
    }, []);

    return(
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="container-fluid pt-5 my-5">
                <div className="card shadow-sm">
                    <div className="card-body">
                        <h1 className="dashome-title text-center fw-bolder">Üdvözöllek a Főoldalon!</h1>
                        {email && (
                            <h5 className="dashhome-winlogin text-center text-danger fw-bold">
                                <span className='text-success'>Sikeres bejelentkezés!</span>
                                <br />Üdvözöllek {email}
                            </h5>
                        )}
                    </div>
                </div>
            </div>
        </main>
    )
}
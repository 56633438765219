import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { collection, getDocs, doc, updateDoc, getFirestore } from 'firebase/firestore';
import { Bar } from 'react-chartjs-2';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';
import 'chart.js/auto';

export default function Analytics() {
  const [currentVisitors, setCurrentVisitors] = useState(0);
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [productViews, setProductViews] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const onlineRef = ref(db, 'analytics/currentVisitors');
    const totalRef = ref(db, 'analytics/totalVisitors');
    const productsCollection = collection(getFirestore(), 'kozep');

    // Fetch current visitors
    onValue(onlineRef, (snapshot) => {
      setCurrentVisitors(snapshot.val() || 0);
    });

    // Fetch total visitors
    onValue(totalRef, (snapshot) => {
      setTotalVisitors(snapshot.val() || 0);
    });

    // Fetch product views from Firestore
    const fetchProductViews = async () => {
      const querySnapshot = await getDocs(productsCollection);
      const products = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        products.push({
          name: data.name,
          views: data.views || 0, // Assuming "views" field stores view count
        });
      });
      setProductViews(products);
    };

    fetchProductViews();

    // Log a page view event
    logEvent(analytics, 'page_view', { page: 'Analytics' });
  }, []);

  // Prepare data for Chart.js
  const productNames = productViews.map((product) => product.name);
  const productViewCounts = productViews.map((product) => product.views);

  const data = {
    labels: productNames,
    datasets: [
      {
        label: 'Product Views',
        data: productViewCounts,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h5>Current Visitors</h5>
                <p>{currentVisitors}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h5>Total Visitors</h5>
                <p>{totalVisitors}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5>Product Views</h5>
                <Bar data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import './RentStyles.css';

export default function RentCars({ setProducts, products, collectionId }) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!collectionId) {
          console.error('Empty collectionId');
          return;
        }    
        const querySnapshot = await getDocs(collection(db, collectionId));
        const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
          ...doc.data(),
        }));
        setProducts(data);
          console.log(`Data fetched from Firebase for collection ${collectionId} successfully`);
        } catch (error) {
          console.error('Error fetching products:', error.message);
        }
      };
    fetchData();
  }, [collectionId]);

      return (
        <div className="categorylist py-5">
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 g-2 my-5">
              {products.map((product) => (
              <div className="col" key={product.id} id="card-category">
                <Link to={`/${collectionId}/${product.id}`} className="text-decoration-none">
                <div
                  className="card card-category" 
                  style={{backgroundColor:'#ffffff', textDecoration:'none', border:'none'}}>
                  <img
                    src={product.imageUrls}
                    className="card-img-top"
                    alt={product.name}
                    style={{

                    }}
                  />
                  <div className="card-body text-center">
                    <div>
                      <h3 className='categorylist-title'>{product.name}</h3>
                      <p className='categorylist-paintingsize'>{product.paintingsize}</p>
                      <div className="d-flex justify-content-center">
                          <p className={`categorylist-description mx-2 ${product.state ? 'featured-underline' : ''}`}>{product.country}</p>
                        {product.state && (
                          <p className='categorylist-description mx-2'>{product.state}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
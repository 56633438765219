import React, {useEffect} from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import ContactForm from "../components/contactform/ContactForm";
import ContactMap from "../components/contactform/ContactMap";
import BreadCrumb from "../components/breadcrumb/BreadCrumb";

export default function Contact(){


    return(
        <>
            <Navbar />
            <BreadCrumb />
            <ContactForm />
            <ContactMap />
            <Footer />
        </>
    )
}
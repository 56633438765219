import React from 'react';
import './DashBoardStyles.css';
import { FaShop } from "react-icons/fa6";
import { Link } from 'react-router-dom';

export default function DashSlide({ openMenu, handleOpenMenu }) {
    return (
        <div className="sidebar col-md-3 col-lg-2 p-1 sidebars py-5 " style={{ backgroundColor: '#212529', height: '100vh' }}>
            <div 
                className={`offcanvas-md offcanvas-end pt-5 ${openMenu ? 'show' : ''}`} 
                tabIndex="-1" 
                id="sidebarMenu" 
                style={{ backgroundColor: '#212529', height: '100vh' }} // Ensure the offcanvas height matches the screen height
                aria-labelledby="sidebarMenuLabel"
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title text-white" id="sidebarMenuLabel">Christinetaylorart</h5>
                    <button 
                        type="button" 
                        className="btn-close bg-white"
                        onClick={handleOpenMenu}
                    ></button>
                </div>
                <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3">
                    {/* Fő navigáció */}
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2 active text-white" to="/dashboard">
                                Főoldal
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2 text-white" to="/dashboard/analytics">
                                Analytika
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2 text-white" to="/dashboard/product-add">
                                Termék feltöltése
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2 text-white" to="/dashboard/tutorial">
                                Tutorial videók
                            </Link>
                        </li>
                        {/* Üzlet szekció */}
                        <li className="nav-item mt-4">
                            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-body-secondary text-uppercase">
                                <span style={{ color: '#d9a95b' }}>Üzlet <FaShop /></span>
                            </h6>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2 text-white" to="/">
                                Üzlet megtekintése
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

import React, {useEffect} from 'react';
import ScrollReveal from 'scrollreveal';
import './AboutStyles.css';
import AboutImage from '../../assets/about/326746413_900758401346914_858099676901041443_n.jpg';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase';
import { initializeApp } from 'firebase/app';

export default function AboutUs() {
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('.aboutus-hr', { delay: 340, origin: 'right' });
        sr.reveal('#lead', { delay: 340, origin: 'right' });
        sr.reveal('#aboutus-title', { delay: 340, origin: 'right' });
        sr.reveal('#about-images', { delay: 340, origin: 'left' });
        return () => {
          sr.destroy();
        };
    }, [])


    return (
        <div className="aboutus py-5" style={{overflowX:'hidden'}}>
            <div className="container my-5">
                <div className="row flex-lg-row-reverse align-items-center g-4 my-5">
                    <div className="col-lg-6 my-2">
                        <h1 className="display-5 text-body-emphasis lh-1 mb-3 about-title" id="aboutus-title">Rólam</h1>
                        <hr className="aboutus-hr" />
                        <p 
                            className="lead" 
                            id="lead" 
                            style={{ 
                                textAlign: '5rem', 
                                wordSpacing: '0px' 
                            }}
                        >Szabó Krisztina vagyok, autodidakta intuitív festő és a Christine art megalkotója. Tinédzser korom óta a lakberendezés és a belsőépítészet területén kibontakozó szenvedély hajtott és ez a kreatív lángolás most is elevenen ég bennem. A mai napig szívesen tevékenykedek ebben a témakörben, imádom a minimál stílust és a letisztult vonalakat. 2019-ben egy spontán pillanatban született az első absztrakt alkotásom, majd a hobbiból 2023-ban szárnyra kelt a művészi vállalkozásom. A kötöttség sosem volt az én világom, így egy saját teret teremtettem, ahol szabadon kibontakozhatok. Minden festmény egy érzés, egy kis szelet a szívemből, ahol az alkotói szabadság szellemében születnek meg azok a művek, amelyek minden ecsetvonással egy új tanulási kaland részei. A festmények nem csupán vizuális élmények, hanem olyan tükör is, amely a belső ént és a folyamatos tanulási kalandot tükrözik. Egy kreatív utazásra hívok mindenkit, bízva a művészi kifejezés erejében, ahol remélem te is megtalálod a saját világod valamelyik festményem egyikében.</p>
                    </div>
                    <div className="col-lg-6 order-first order-lg-last my-2">
                        <img
                            src={AboutImage}
                            className="d-block mx-lg-auto img-fluid about-image"
                            id='about-images'
                            alt="AboutImage"
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

